import { T_Product, T_Coupon, T_Order, T_OrderProduct, T_PPV, NullableDate } from './types';
import { AFFILIATION, SPAs } from './config';
import { useCategory } from './helpers/useBrand';

export const SegmentECommerceEvents = {
  ADD_TO_CART: 'Add to Cart',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_REMOVED: 'Product Removed',
  CHECKOUT_STARTED: 'Checkout Started',
  ORDER_COMPLETED: 'Order Completed',
  EVENT_PREQUAL_ADDRESS_CHECK: 'Broadband Availability Checked',
  RETURN_TO_CART: 'Cart Viewed',
  PROMOTION_CLICKED: 'Promotion Clicked',
  LABEL_AND_URL: 'Cta Clicked',
  SIGNED_IN: 'Signed In',
  PAYMENT_INFO_ENTERED: 'Payment Info Entered',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  VTV_MIGRATION_COMPLETE: 'VTV Migration Complete',
  PRODUCT_EXPLORED: 'Promotion Clicked',
  PRODUCT_CANCELLED: 'Product Cancelled',
  TOGGLE_CLICK: 'Toggle Clicked',
};

export const SKY_BRAND = 'Sky';
export const PPV = 'PPV';
export const BROADBAND = 'BROADBAND';
export const BUNDLE_OFFER = 'BUNDLE_OFFER';
export const GET_OFFER = 'Get Offer';

export const BroadbandFunnelTypes = {
  FIBRE_SWAP: 'fibre swap',
  INTACT_ONT: 'intact ONT',
  NEW_FIBRE: 'new fibre',
};

export const QuizEvents = {
  QUIZ_LAUNCHED: 'Quiz Launched',
  QUIZ_INTERACTION: 'Quiz Interaction',
  QUIZ_COMPLETED: 'Quiz Completed',
};

export const QuizInteractionTypes = {
  LAUNCH: 'launch',
  PAGE_VIEW: 'page view',
  BUTTON_CLICK: 'button click',
  ADDRESS_ENTERED: 'address entered',
  FINISH: 'finish',
  NA: 'n/a',
};

export const FaultSwapEvents = {
  BUTTON_CLICK: 'button click',
  TRACK_ISSUES_WITH_THE_DEVICE: 'track issues with the device',
  ORDER_COMPLETED: 'Fault Swap Order Completed',
};
export const BookATechEvents = {
  tech_service_continue_clicked: 'tech_service_continue_clicked',
  Confirm_Details: 'confirm_details_clicked',
  Submit: 'submit_clicked',
  Rechedule: 'reschedule_submit_clicked',
  confirm_cancellation_clicked: 'confirm_cancellation_clicked',
};
export const SatelliteMigrationEvent = {
  verification_submit_clicked: 'verification_submit_clicked',
  prize_link_clicked: 'prize_link_clicked',
  book_tech_clicked: 'book_tech_clicked',
  video_Tracking: 'video_Tracking',
};

class SClient {
  getPageProperties() {
    return {
      title: document.title, // Page title
      url: window.location.href, // Full URL of the page
      path: window.location.pathname, // Path (e.g., /home, /about)
      referrer: document.referrer, // Referrer (if available)
    };
  }
  track(event: string, properties?: any, options?: any, callback?: any) {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      return;
    }

    try {
      // Merge the page data with the custom properties
      const finalProperties = {
        ...this.getPageProperties(), // Include page-related properties
        ...properties, // Include any custom properties provided
      };

      // Track the event with the merged properties
      segmentFromWindowObject.track(event, finalProperties, options, callback);
    } catch (err) {
      console.error('Error tracking event:', err);
    }
  }
  // track(event: string, properties?: any, options?: any, callback?: any) {
  //   let segmentFromWindowObject = (window as any).analytics;
  //   if (!segmentFromWindowObject) {
  //     return;
  //   }
  //   console.log("segmentFromWindowObject.page()",(window as any))
  //   try {
  //     segmentFromWindowObject?.track(event, properties, options, callback);
  //   } catch (err) {}
  // }

  identify(accountId: string, email: string, dthAccountNumber: string, profileId: string) {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      console.error('Segment disabled.');
      return;
    }
    let traits = {
      account_id: accountId,
      email: email,
      dth_account_number: dthAccountNumber,
      profile_id: profileId,
    };
    try {
      segmentFromWindowObject.identify(accountId, traits);
    } catch (err) {
      console.error(err);
    }
  }

  identifyAnonymousUser(email: string, firstName: string, dob: NullableDate) {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      console.error('Segment disabled.');
      return;
    }
    let traits = {
      email: email,
      firstname: firstName,
      date_of_birth: dob,
    };
    try {
      segmentFromWindowObject.identify(email, traits);
    } catch (err) {
      console.error(err);
    }
  }

  resetIdentity() {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      console.error('Segment disabled.');
      return;
    }
    try {
      segmentFromWindowObject?.reset();
    } catch (err) {
      console.error(err);
    }
  }

  page() {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      console.error('Segment disabled.');
      return;
    }
    try {
      segmentFromWindowObject.page();
    } catch (err) {
      console.error(err);
    }
  }
  //BB Added or Removed
  bbProductAddOrRemove(product: any, isUpgrade: boolean, quantity = 1, price?: any, isBBAdded?: boolean) {
    this.track(isBBAdded ? SegmentECommerceEvents.PRODUCT_ADDED : SegmentECommerceEvents.PRODUCT_REMOVED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: BROADBAND,
      brand: SKY_BRAND,
      category: useCategory(product.categoryId),
      name: product.name,
      price: price,
      quantity,
      id: product.sku,
      variant: product.categoryName,
      is_segment: 'yes',
    });
  }

  //GA for Product Addition
  productAdded(product: T_Product, isUpgrade: boolean, quantity = 1, packageType?: string) {
    this.track(SegmentECommerceEvents.PRODUCT_ADDED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: packageType ? packageType : useCategory(product.categoryId),
      brand: SKY_BRAND,
      category: useCategory(product.categoryId),
      name: product.name,
      price: product.price0,
      quantity,
      id: product.sku,
      variant: product.categoryName,
      is_segment: 'yes',
    });
  }
  //Exploring BB
  exploreBB(product: any, price?: any, explore?: boolean) {
    this.track(SegmentECommerceEvents.PRODUCT_EXPLORED, {
      cart_type: 'upgrade',
      package_type: BROADBAND,
      brand: SKY_BRAND,
      category: BROADBAND,
      name: product?.name,
      price: price,
      quantity: 1,
      id: product.sku,
      variant: product.categoryName,
      is_segment: 'yes',
    });
  }

 //BB Add to Cart Handled
 BBCheckout(product: any,isUpgrade: boolean) {
  this.track(SegmentECommerceEvents.PRODUCT_ADDED, {
    products_added: product,
    cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
    package_type: BROADBAND,
    brand: SKY_BRAND,
    quantity: 1,
    is_segment: 'yes',
  });
}

  // User Clicks 'Book Now' on PPV Movie Product on My Account Dashboard
  ppvAdded(product: T_PPV) {
    this.track(SegmentECommerceEvents.PRODUCT_ADDED, {
      cart_type: 'upgrade',
      package_type: PPV,
      brand: SKY_BRAND,
      category: PPV,
      name: product.title,
      price: product.offerings[0].price / 100,
      quantity: 1,
      id: product.id,
      variant: product.group,
      is_segment: 'yes',
    });
  }
//Bundle Offer Banner Click in myaccount Dashboard
  getOfferDetails(offerPrice:any,offerPage:any) {
    this.track(SegmentECommerceEvents.PROMOTION_CLICKED, {
      cart_type: 'upgrade',
      package_type: BUNDLE_OFFER,
      brand: SKY_BRAND,
      category: BUNDLE_OFFER,
      name: GET_OFFER,
      url: offerPage,
      price: offerPrice,
      quantity: 1,
      is_segment: 'yes',
    });
  }

  ppvBooked(
    orderId: number,
    sku: string,
    name: string,
    price: number,
    total: number,
    affiliation: string,
    funnelType: string,
    brand: string,
    coupon?: string,
    variant?: string
  ) {
    this.track(SegmentECommerceEvents.ORDER_COMPLETED, {
      cart_type: affiliation,
      package_type: PPV,
      coupon: coupon,
      value: Number((total ?? 0).toFixed(2)),
      order_id: orderId ? orderId + '' : '',
      is_segment: 'yes',
      products: [
        {
          brand: SKY_BRAND,
          category: PPV,
          name: name,
          price: price,
          quantity: 1,
          id: sku,
          variant: variant,
        },
      ],
    });
  }

  productRemoved(product: T_Product, isUpgrade: boolean, quantity = 1, packageType?: string) {
    this.track(SegmentECommerceEvents.PRODUCT_REMOVED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: packageType ? packageType : useCategory(product.categoryId),
      brand: SKY_BRAND,
      category: useCategory(product.categoryId),
      name: product.name,
      price: product.price0,
      quantity,
      id: product.sku,
      variant: product.categoryName,
      is_segment: 'yes',
    });
  }

  // User Starts Checkout by Clicking the 'Checkout Button' and Landing on https://cart.sky.co.nz/checkout/account-details
  checkoutStarted(
    order: T_Order,
    isUpgrade: boolean,
    coupon?: T_Coupon,
    variant?: string,
    isAbandoned?: boolean,
    packageType?: string
  ) {
    let total = order.orderTotals.find(ot => ot.className === 'ot_total');
    this.track(SegmentECommerceEvents.CHECKOUT_STARTED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: packageType,
      is_abandoned: isAbandoned ?? false,
      coupon: coupon?.couponCode,
      value: Number((total?.value ?? 0).toFixed(2)),
      products: order?.orderProducts?.map(op => ({
        brand: SKY_BRAND,
        category: useCategory(op?.categoryId),
        name: op?.product?.name,
        price: op?.price,
        quantity: op?.quantity,
        id: op?.product?.sku,
        variant: op?.categoryName,
      })),
      is_segment: 'yes',
    });
  }

  simulationProductAdded(product: T_OrderProduct, isUpgrade: boolean, quantity = 1) {
    this.track(SegmentECommerceEvents.PRODUCT_ADDED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: useCategory(product.categoryId),
      brand: SKY_BRAND,
      category: useCategory(product.categoryId),
      name: product.product.name,
      price: product.price,
      quantity,
      id: product.product.sku,
      variant: product.categoryName,
      is_segment: 'yes',
    });
  }

  // Simulation is defined as a customer going straight to Checkout.
  simulationProductsAdded(order: T_Order, isUpgrade: boolean, coupon?: T_Coupon, variant?: string) {
    order.orderProducts.forEach(product => {
      this.simulationProductAdded(product, isUpgrade, 1);
    });
  }

  // 1.  Logged in User returns to broadband cart with items in their cart and begins checkout
  // 2.  User logs into their account, navigates to My Account > Manage Subscription and saved items are in the cart from a previous session
  returnToAbandonedCart(orderProducts: T_OrderProduct[], isUpgrade: boolean, whichCart: string, packageType?: string) {
    this.track(
      whichCart === SPAs.MYACCOUNT ? SegmentECommerceEvents.RETURN_TO_CART : SegmentECommerceEvents.PRODUCT_ADDED,
      {
        cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
        package_type: packageType,
        is_abandoned: true,
        products: orderProducts.map(op => ({
          brand: SKY_BRAND,
          category: useCategory(op.categoryId),
          name: op.product.name,
          price: op.product.price1,
          quantity: op.quantity,
          id: op.product.sku,
          variant: op.categoryName,
        })),
        is_segment: 'yes',
      }
    );
  }

  // VTV User Completes Order and lands on the order confirmation page.
  vtvMigrationCompleted() {
    this.track(SegmentECommerceEvents.VTV_MIGRATION_COMPLETE, {});
  }

  // User Completes Order and lands on the order confirmation page.
  orderCompleted(
    workOrderNumber: string,
    isUpgrade: boolean,
    order: T_Order,
    coupons?: T_Coupon[],
    packageType?: string,
    accountNumber?: string
  ) {
    let total = order.orderTotals.find(ot => ot.className === 'ot_total');
    this.track(SegmentECommerceEvents.ORDER_COMPLETED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: packageType,
      coupon: coupons?.map(coupon => coupon.couponCode).join(','),
      value: Number((total?.value ?? 0).toFixed(2)),
      order_id: workOrderNumber,
      subscriber_account_number: accountNumber,
      products: order.orderProducts.map(op => ({
        brand: SKY_BRAND,
        category: useCategory(op.categoryId),
        name: op.product.name,
        price: op.product.priceIncTax,
        quantity: op.quantity,
        id: op.product.sku,
        variant: op.categoryName,
      })),
      is_segment: 'yes',
    });
  }

  prequalAddressChecked(isUpgrade: boolean, addressResult: string) {
    this.track(SegmentECommerceEvents.EVENT_PREQUAL_ADDRESS_CHECK, {
      availability_status: addressResult,
    });
  }

  promotionClicked(promotionButtonText: string, promotionCreative: string, promotionName: string) {
    this.track(SegmentECommerceEvents.PROMOTION_CLICKED, {
      promotion_button_text: promotionButtonText,
      promotion_creative: promotionCreative,
      promotion_name: promotionName,
      is_segment: 'yes',
    });
  }
  //CTA Which directs to another URL (track 'Cta Clicked', URL and button label)
  trackLabelAndUrl(navigationCtaText: string, navigationCtaURL: string) {
    this.track(SegmentECommerceEvents.LABEL_AND_URL, {
      link_text: navigationCtaText,
      link_url: navigationCtaURL,
    });
  }

  // User Signs In
  signedIn(accountNumber: string) {
    this.track(SegmentECommerceEvents.SIGNED_IN, { subscriber_account_number: accountNumber });
  }

  //checkout step 1, user clicks 'next' on checkout/account-form page
  checkoutStepCompleted(
    isUpgrade: boolean,
    order: T_Order,
    paymentMethod: string,
    step: number,
    coupons?: T_Coupon[],
    packageType?: string
  ) {
    let total = order.orderTotals.find(ot => ot.className === 'ot_total');
    this.track(SegmentECommerceEvents.CHECKOUT_STEP_COMPLETED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: packageType,
      coupon: coupons?.map(coupon => coupon.couponCode).join(','),
      value: Number((total?.value ?? 0).toFixed(2)),
      payment_method: paymentMethod,
      step: step,
      products: order.orderProducts.map(op => ({
        brand: SKY_BRAND,
        category: useCategory(op.categoryId),
        name: op.product.name,
        price: op.product.priceIncTax,
        quantity: op.quantity,
        id: op.product.sku,
        variant: op.categoryName,
      })),
    });
  }

  //User successfully enters credit card details via payment express and is taken to confirmation page
  paymentInfoEntered(
    workOrderNumber: string,
    isUpgrade: boolean,
    paymentMethod: string,
    order?: T_Order,
    coupons?: T_Coupon[],
    packageType?: string
  ) {
    let total = order?.orderTotals.find(ot => ot.className === 'ot_total');
    this.track(SegmentECommerceEvents.PAYMENT_INFO_ENTERED, {
      cart_type: isUpgrade ? AFFILIATION.UPG : AFFILIATION.ACQ,
      package_type: packageType,
      coupon: coupons?.map(coupon => coupon.couponCode).join(','),
      value: Number((total?.value ?? 0).toFixed(2)),
      payment_method: paymentMethod,
      order_id: workOrderNumber,
      products: order?.orderProducts.map(op => ({
        brand: SKY_BRAND,
        category: useCategory(op.categoryId),
        name: op.product.name,
        price: op.product.priceIncTax,
        quantity: op.quantity,
        id: op.product.sku,
        variant: op.categoryName,
      })),
    });
  }

  trackQuiz(
    event: string,
    quizStepHeader: string,
    quizInteractionTypes: string,
    quizInteractionDetails: string | null,
    quizSelections: string | string[],
    quizProgressStatus: string,
    quizRecommendedPackage: string,
    quizRecommendedProducts: string | undefined
  ) {
    this.track(event, {
      quiz_step_header: quizStepHeader,
      quiz_interaction_type: quizInteractionTypes,
      quiz_interaction_details: quizInteractionDetails,
      quiz_selections: quizSelections,
      quiz_progress_status: quizProgressStatus,
      quiz_recommended_package: quizRecommendedPackage,
      quiz_recommended_products: quizRecommendedProducts,
    });
  }

  faultSwapBtnClick(btnText: string) {
    this.track(FaultSwapEvents.BUTTON_CLICK, { button_text: btnText });
  }

  trackIssuesWithDevice(isKeywordFound: boolean, errorDescription: string) {
    this.track(FaultSwapEvents.TRACK_ISSUES_WITH_THE_DEVICE, {
      is_keyword_found: isKeywordFound,
      error_description: errorDescription,
    });
  }

  faultSwapOrderCompleted(
    workOrderNumber: string,
    accountNumber: string,
    deviceRequestType: string,
    deviceType: string,
    deviceName: string
  ) {
    this.track(FaultSwapEvents.ORDER_COMPLETED, {
      order_id: workOrderNumber,
      subscriber_account_number: accountNumber,
      device_request_type: deviceRequestType,
      device_type: deviceType,
      device_name: deviceName,
    });
  }

  toggleClicked(actionOrigin: string, toggleState: string, toggleType: string) {
    this.track(SegmentECommerceEvents.TOGGLE_CLICK, {
      actionOrigin: actionOrigin,
      toggleState: toggleState,
      toggleType: toggleType,
    });
  }

  bookATechCancel(visitDate: string, visitTime: string, account_id: string, alertMessage: string) {
    this.track(BookATechEvents.confirm_cancellation_clicked, {
      visitDate: visitDate,
      visitTime: visitTime,
      account_id: account_id,
      alertMessage: alertMessage,
    });
  }

  bookATechRechedule(visitDate: string, visitTime: string, account_id: string, alertMessage: string) {
    this.track(BookATechEvents.Rechedule, {
      visitDate: visitDate,
      visitTime: visitTime,
      account_id: account_id,
      alertMessage: alertMessage,
    });
  }

  bookATech_Tech_Service_Continue(bookingReason: string, account_id: string) {
    this.track(BookATechEvents.tech_service_continue_clicked, {
      bookingReason: bookingReason,
      account_id: account_id,
    });
  }
  bookATech_Confirm_Details(account_id: string) {
    this.track(BookATechEvents.Confirm_Details, {
      account_id: account_id,
    });
  }
  bookATechSubmit(visitDate: string, visitTime: string, account_id: string, alertMessage: string) {
    this.track(BookATechEvents.Submit, {
      visitDate: visitDate,
      visitTime: visitTime,
      account_id: account_id,
      alertMessage: alertMessage,
    });
  }
  SatelliteMigrationSubmit(accountNumber: string, toggleState: string, alertMessage: string) {
    this.track(SatelliteMigrationEvent.verification_submit_clicked, {
      accountNumber: accountNumber,
      toggleState: toggleState,
      alertMessage: alertMessage,
    });
  }
  SatelliteMigrationPrizeClicked(linkText: string, linkUrl: string) {
    this.track(SatelliteMigrationEvent.prize_link_clicked, {
      linkText: linkText,
      linkUrl: linkUrl,
    });
  }
  SatelliteMigrationBookTechClicked(linkText: string, linkUrl: string) {
    this.track(SatelliteMigrationEvent.book_tech_clicked, {
      linkText: linkText,
      linkUrl: linkUrl,
    });
  }
}

let segment = new SClient();
export { segment };
